import React from 'react'
import { graphql } from 'gatsby'

import Card from '../components/Card'
import FancyImage from '../components/FancyImage'
import Footer from '../components/Footer'
import GradientContainer from '../components/GradientContainer'
import Hero from '../components/Hero'
import NavigationBar from '../components/NavigationBar'
import { SEO } from '../components/SEO'

const contact = ({ data }) => {

  const d = data.contactYaml

  return (
    <div>
      <GradientContainer>
        <div className='container mx-auto'>
          <div className='md:mb-24'>
            <NavigationBar />
            <Hero
              title='Contact Us'
              description={<ContactDescription />}
              featured={
                <FancyImage image={d.contact_us_image} classes='bg-dark' x={-20} y={20} />
              }
            />
          </div>
        </div>
      </GradientContainer>
      <Footer />
    </div>
  )
}

const ContactDescription = () => {
  return (
    <>
      <p className='mb-8 text-lg md:text-xl'>
        Contact us and we'll get back to you as soon as we can. We look forward
        to hearing from you!
      </p>
      <ContactForm />
    </>
  )
}

const ContactForm = () => {
  return (
    <>
      <Card classes='bg-dark' x={20} y={20}>
        <form
          name='Contact'
          method='POST'
          data-netlify='true'
          action='/success'
        >
          <div className='flex flex-col gap-4'>
            <input type='hidden' name='form-name' value='Contact' />
            <div className='flex flex-col gap-2'>
              <label htmlFor='name'>Name</label>
              <input type='text' name='name' id='name' />
            </div>
            <div className='flex flex-col gap-2'>
              <label htmlFor='email'>Email</label>
              <input type='email' name='email' id='email' />
            </div>
            <div className='flex flex-col gap-2'>
              <label htmlFor='message'>Message</label>
              <textarea name='message' id='message' rows='5' />
            </div>
            <button
              className='px-6 py-2 mx-auto text-sm font-bold text-white uppercase transition duration-300 ease-in-out transform border-2 rounded-lg w-min md:text-base hover:scale-110 bg-secondary border-secondary'
              type='submit'
            >
              Send
            </button>
          </div>
        </form>
      </Card>
    </>
  )
}

export default contact

export const Head = () => <SEO title='Viranim - Contact Us' />

export const query = graphql`
  query ContactUsQuery {
    contactYaml {
      contact_us_image
    }
  }
`
